<template>
  <div id="information" class="container">
   

    <div class="card-body">
          <div class="basic-form">
            <form>
              <div class="row">
                <div class="mb-3 col-md-6">
                  <label class="form-label">Görüş veya öneri bildir</label>
                  <el-input
                    class="form-control"
                    :class="{
                      'input-validation-error': userResponse.ValidationErrors.some((x) => x.Key == 'Content'),
                    }"
                    placeholder="Görüş veya önerinizi yazınız..."
                    type="textarea"
  :rows="12"
                    v-model="userFormInputs.Content"
                  ></el-input>
                  <span class="validation-error" v-if="userResponse.ValidationErrors.some((x) => x.Key == 'Content')">{{ userResponse.ValidationErrors.filter((x) => x.Key == "Content")[0].Value }}</span>
                </div>
              
                

                <div class="col-6">
                
                </div>
                <div class="col-6">
                  <el-button :loading="loading" class="bg-primary py-3 w-100" @click="updateUserInfo">Kaydet</el-button>
                </div>
              </div>
            </form>
          </div>
        </div>
  
 

  </div>
</template>

<script>
import { deepCopyObject } from "../../helpers/generalHelper";
import META_DATA from "../../constants/metaData";
import notFoundInfo from "../../components/notFound/notFoundInfo.vue";
import geocoder from "geocoder";
import { mapGetters } from "vuex";
export default {
  metaInfo() {
    return {
      title: META_DATA.PROFILE.Title,
    };
  },
  data() {
    return {
      location: "",
      places: [],
      selected: {},
      service: null,
      apiKey: "AIzaSyCjF3pCyknt07yWnY9N9d9MIPXkyZinAfg",
      step: 0,
      changePhotoDialog: false,
      deleteVehicleDialog: false,
      vehicleId: null,
      selectedVehicleType: null,
      firstRender: true,
      firstRenderBillDistrict: true,
      firstRenderWarehouseDistrict: true,
      isWarehouse: null,
      marker: {
        position: {
          lat: 45.508,
          lng: -73.587,
        },
      },
      mapOptions: {
        disableDefaultUI: true,
      },
      center: { lat: 45.508, lng: -73.587 },
      invoiceDialog: false,
      deleteDialog: false,
      passwordDialog: false,
      signOutDialog: false,
      dorseDialog: false,
      imageUrl: "",
      invitecode: "",
      currentPassword: "",
      newpassword: "",
      newpassword2: "",
      dialogImageUrl: "",
      imgDialog: false,
      vehicleDialog: false,
      active: 1,
      blockedUsers: [],
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
      ],
      value: "",
      nextbtn: "Geri",
      personalInfoDistricts: null,
      billFormDistricts: null,
      BillForm: {
        CompanyLogo: null,
        Title: null,
        Email: null,
        PhoneNumber: null,
        CityTitle: null,
        DistrictTitle: null,
        TaxNo: null,
        TaxOffice: null,
        Address: null,
      },
      warehouses: null,
      WarehouseForm: {
        a: null,
        Title: null,
        City: null,
        District: null,
        Lat: 0,
        Lon: 0,
        Address: null,
        Phone: null,
      },
      warehouseFormDistricts: null,
      NotificationSettingsInputs: {},
      VehicleForm: {
        VehicleId: null,
        PermitSerialNo: null,
        PlateNo: null,
        TotalWeight: null,
        Brand: null,
        Model: null,
        Year: null,
        TrailerPlateNo: null,
        TrailerSerialNo: null,
        FirstName: null,
        LastName: null,
        BirthDate: null,
        DriverTC: null,
        DriverLicenceNo: null,
        DriverPhone: null,
        TruckTrailerImage: null,
        TrailerImage: null,
        DriverImage: null,
        DriverProofOfLocation: null,
        TrailerId: null,
      },
      userFormInputs: {
        Content: "",
        IsWeb: true
      },
      depotDialog: false,
      removeBlockDialog: false,
      depotResponse: { ValidationErrors: [] },
      resetObj: { ValidationErrors: [] },
      isUpdate: false,
    };
  },
  async created() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.tab) {
      this.step = parseInt(params.tab);
      this.isWarehouse = this.step === 1 ? true : false;
    }
    await this.$store.dispatch("getCities");
    await this.$store.dispatch("getTrailers");
    this.token = this.$store.getters["getToken"];
    this.blockedUsers = this.user.BlockedUsers;
    this.BillForm = deepCopyObject(this.$store.getters["userState"].InvoiceInfo);
    // const result = await this.$store.dispatch("getDistrict", this.user.CityTitle);

    // if (result)
    this.userFormInputs = deepCopyObject(this.user);
  
    console.log("this.$store.state.User: ", this.$store.state.User);
    this.warehouse = this.$store.state.User.warehouses;
    this.NotificationSettingsInputs = deepCopyObject(this.user.NotificationSettings);
    // this.personalInfoDistricts = await this.$getDistricts(
    //   this.userFormInputs.CityTitle
    // );

    // control invoice query
    if (this.$route.query.invoiceDialog === true) this.invoiceDialog = true;
  },
  async mounted() {
    // this.MapsInit();
    this.$root.$emit("openAddDepotModal", () => {
      this.depotDialog = true;
    });
   
  },
  destroyed() {
    this.resetResponses();
  },
  computed: {
    ...mapGetters({
      cityState: "cityState",
    }),
    cityInfo() {
      return this.$store.getters["cityInfo"];
    },
    token: {
      get() {
        return localStorage.getItem("token");
      },
      set(newValue) {
        return newValue;
      },
    },
    vehicleResponse: {
      get() {
        return this.$store.getters["vehicleResponse"];
      },
      set(newValue) {
        this.$store.commit("vehicleResponse", newValue);
      },
    },
    inviteFriendResponse: {
      get() {
        return this.$store.getters["inviteFriendResponse"];
      },
      set(newValue) {
        this.$store.commit("inviteFriendResponse", newValue);
      },
    },
    warehouseId: {
      get() {
        return this.$store.getters["warehouseId"];
      },
      set(newValue) {
        this.$store.commit("warehouseId", newValue);
      },
    },
    bankResponse: {
      get() {
        return this.$store.getters["bankResponse"];
      },
      set(newValue) {
        this.$store.commit("bankResponse", newValue);
      },
    },
    userResponse: {
      get() {
        return this.$store.getters["userResponse"];
      },
      set(newValue) {
        this.$store.commit("userResponse", newValue);
      },
    },
    invoiceResponse: {
      get() {
        return this.$store.getters["invoiceResponse"];
      },
      set(newValue) {
        this.$store.commit("invoiceResponse", newValue);
      },
    },
    changePasswordResponse: {
      get() {
        return this.$store.getters["changePasswordResponse"];
      },
      set(newValue) {
        this.$store.commit("changePasswordResponse", newValue);
      },
    },
    user: {
      get() {
        return this.$store.getters["userState"];
      },
      set(newValue) {
        this.$store.commit("userCommit", newValue);
      },
    },
    loading() {
      return this.$store.getters["loading"];
    },
  },
  watch: {
    location: function() {
      if (this.service === null) {
        this.MapsInit();
      }
      if (this.location.length === 0) {
        this.clearForm();
      }
    },
    WarehouseForm: function() {
      console.log("this.WarehouseForm change", this.WarehouseForm);
    },
  
    NotificationSettingsInputs: {
      handler: function() {
        if (!this.firstRender) {
          this.$store.dispatch("updateNotificationSettingsCareer", this.NotificationSettingsInputs);
        }
        this.firstRender = false;
      },
      deep: true,
    },
    step: {
      handler: function() {
        this.resetResponses();
      },
      deep: true,
    },
  },
  methods: {
    filteredCities() {
      return this.cityState?.filter((item) => item && item.ID);
    },
    getVehicleName() {
      if (this.selectedVehicleType == 0) return "Tır";
      if (this.selectedVehicleType == 1) return "Kamyon";
      if (this.selectedVehicleType == 2) return "Kamyonet";
    },
    MapsInit: function() {
      this.service = new window.google.maps.places.AutocompleteService();
    },
    search: async function() {
      try {
        const results = await this.service.getPlacePredictions({
          input: this.location,
          // types: ["(cities)"],
          componentRestrictions: {
            country: "tr",
          },
        });
        this.places = results.predictions;
      } catch (error) {
        console.log("error search: ", error);
      }
    },
    getLatLong: function(location) {
      try {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
          {
            placeId: location,
          },
          async (results, status) => {
            if (status == window.google.maps.GeocoderStatus.OK) {
              this.marker.position.lat = await results[0].geometry.location.lat();
              this.marker.position.lng = await results[0].geometry.location.lng();
              this.center = {
                lat: this.marker.position.lat,
                lng: this.marker.position.lng,
              };
            }
          },
        );
      } catch (error) {
        console.log(`error getLatLong: ${error}`);
      }
    },
    selectPlace: function(place) {
      this.location = place.description;
      this.selected.description = place.description;
      this.selected.location_id = place.place_id;
      this.getLatLong(place.place_id);
      this.places = [];
    },
    clearForm: function() {
      this.location = "";
      this.selected = {};
      this.places = [];
      this.$emit("selectedArea", {});
      this.geolocate();
    },
    handleChangePhoto(data) {
      if (!data.HasError) {
        var req = {
          FirstName: this.userFormInputs.FirstName,
          LastName: this.userFormInputs.LastName,
          PhoneNumber: this.$phoneNumberFormatString(this.userFormInputs.PhoneNumber),
          // CityTitle: this.userFormInputs.CityTitle,
          // DistrictTitle: this.userFormInputs.DistrictTitle,
          WorkCityId: this.userFormInputs.WorkCityId,
          TargetWorkCityId: this.userFormInputs.TargetWorkCityId,
          VehicleType: this.userFormInputs.VehicleType,
          Email: this.userFormInputs.Email,
          ImageUrl: data.Data.Url,
        };
        this.$store.dispatch("updateUserInfo", req);
        window.sendNotification.success(data.Message);
      } else window.sendNotification.error(data.Message);
    },
    updateTotalWeight(e) {
      this.VehicleForm.TotalWeight = e.replace(/[^\d]/g, "");
    },
    updateYear(e) {
      this.VehicleForm.Year = e.replace(/[^\d]/g, "");
    },
    updateDriverTC(e) {
      this.VehicleForm.DriverTC = e.replace(/[^\d]/g, "");
    },
    handlePasswordDialog() {
      this.changePasswordResponse = { ValidationErrors: [] };
      this.passwordDialog = false;
    },
    getCopiedObject(item) {
      return deepCopyObject(item);
    },
    showPassword() {
      const passwordType = this.$("#password").attr("type");
      if (passwordType === "text") {
        this.$("#togglePassword").removeClass("bi-eye");
        this.$("#togglePassword").addClass("bi-eye-slash");
        this.$("#password").attr("type", "password");
      } else if (passwordType === "password") {
        this.$("#togglePassword").removeClass("bi-eye-slash");
        this.$("#togglePassword").addClass("bi-eye");
        this.$("#password").attr("type", "text");
      }
    },
    showPasswordNew() {
      const passwordNewType = this.$("#passwordNew").attr("type");
      if (passwordNewType === "text") {
        this.$("#togglePasswordNew").removeClass("bi-eye");
        this.$("#togglePasswordNew").addClass("bi-eye-slash");
        this.$("#passwordNew").attr("type", "password");
      } else if (passwordNewType === "password") {
        this.$("#togglePasswordNew").removeClass("bi-eye-slash");
        this.$("#togglePasswordNew").addClass("bi-eye");
        this.$("#passwordNew").attr("type", "text");
      }
    },
    showPasswordNewRetry() {
      const passwordNewRetryType = this.$("#passwordNewRetry").attr("type");
      if (passwordNewRetryType === "text") {
        this.$("#togglePasswordNewRetry").removeClass("bi-eye");
        this.$("#togglePasswordNewRetry").addClass("bi-eye-slash");
        this.$("#passwordNewRetry").attr("type", "password");
      } else if (passwordNewRetryType === "password") {
        this.$("#togglePasswordNewRetry").removeClass("bi-eye-slash");
        this.$("#togglePasswordNewRetry").addClass("bi-eye");
        this.$("#passwordNewRetry").attr("type", "text");
      }
    },
    clearDepotResponse() {
      this.depotResponse = this.resetObj;
      this.depotDialog = false;
      this.location = "";
    },
    clearInvoiceResponse() {
      this.invoiceResponse = this.resetObj;
      this.BillForm = deepCopyObject(this.$store.getters["userState"].InvoiceInfo);
      this.invoiceDialog = false;
    },
    resetResponses() {
      this.vehicleResponse = this.resetObj;
      this.bankResponse = this.resetObj;
      this.depotResponse = this.resetObj;
      this.userResponse = this.resetObj;
      this.invoiceResponse = this.resetObj;
      this.changePasswordResponse = this.resetObj;
      this.inviteFriendResponse = this.resetObj;
    },
    handleRemoveBlock(userId) {
      this.currentBlockedUserId = userId;
      this.removeBlockDialog = true;
    },
    removeBlock() {
      this.$store.dispatch("removeBlock", {
        UserId: this.currentBlockedUserId,
      });
      this.removeBlockDialog = false;
    },
    async handleCityChange(CityTitle) {
      this.personalInfoDistricts = await this.$getDistricts(CityTitle);
      if (!this.firstRenderUserDistrict) {
        this.userFormInputs.DistrictTitle = null;
      }
      this.firstRenderUserDistrict = false;
    },
    async inviteFriend() {
      this.$store.dispatch("inviteFriend", {
        Email: this.invitecode,
      });
    },
    deleteVehicle(vehicleId) {
      this.$store.dispatch("deleteVehicle", { Id: vehicleId });
      this.deleteVehicleDialog = false;
    },
    selectTrailerType(TrailerId) {
      this.VehicleForm.TrailerId = TrailerId;
      this.dorseDialog = false;
    },
    handleTruckImageUploadSuccess(res) {
      if (res.HasError) {
        window.sendNotification.error(res.Message);
      } else {
        this.VehicleForm.TruckTrailerImage = res.Data.Url;
      }
    },
    handleTrailerImageUploadSuccess(res) {
      if (res.HasError) {
        window.sendNotification.error(res.Message);
      } else {
        this.VehicleForm.TrailerImage = res.Data.Url;
      }
    },
    handleDriverImageUploadSuccess(res) {
      if (res.HasError) {
        window.sendNotification.error(res.Message);
      } else {
        this.VehicleForm.DriverImage = res.Data.Url;
      }
    },
    handleLocationImageUploadSuccess(res) {
      if (res.HasError) {
        window.sendNotification.error(res.Message);
      } else {
        this.VehicleForm.DriverProofOfLocation = res.Data.Url;
      }
    },
    async updateVehicle() {
      this.VehicleForm.Type = this.selectedVehicleType;
      const result = await this.$store.dispatch("updateVehicle", {
        VehicleForm: this.VehicleForm,
      });
      if (!result.HasError) this.vehicleDialog = false;
    },
    addVehicleDialog() {
      this.active = 1;
      this.vehicleDialog = true;
      this.isUpdate = false;
      this.VehicleForm = {
        VehicleId: null,
        PermitSerialNo: null,
        PlateNo: null,
        TotalWeight: null,
        Brand: null,
        Model: null,
        Year: null,
        TrailerPlateNo: null,
        TrailerSerialNo: null,
        FirstName: null,
        LastName: null,
        BirthDate: null,
        DriverTC: null,
        DriverLicenceNo: null,
        DriverPhone: null,
        TruckTrailerImage: null,
        TrailerImage: null,
        DriverImage: null,
        DriverProofOfLocation: null,
        TrailerId: null,
      };
    },
    selectVehicleType(type) {
      this.active++;
      this.selectedVehicleType = type;
    },
    deleteWarehouse(warehouse) {
      this.$store.dispatch("deleteWarehouse", {
        Id: { Id: warehouse },
        isWarehouse: this.isWarehouse,
      });

      this.deleteDialog = false;
    },
    newWarehouse() {
      this.depotDialog = true;
      this.warehouseFormDistricts = null;
      this.WarehouseForm = {
        Title: null,
        City: null,
        District: null,
        Lat: 0,
        Lon: 0,
        Address: null,
        Phone: null,
      };
      this.geolocate();
    },
    // eslint-disable-next-line no-debugger
    selectWarehouse(warehouse) {
      this.marker.position.lng = warehouse.Lon;
      this.marker.position.lat = warehouse.Lat;
      this.center.lat = warehouse.Lat;
      this.center.lng = warehouse.Lon;
      this.depotDialog = true;
      this.location = warehouse.Address;
      warehouse.City = warehouse.CityTitle;
      warehouse.District = warehouse.DistrictTitle;
      this.WarehouseForm = deepCopyObject(warehouse);
    },

    async updateWarehouse() {
      this.WarehouseForm.Phone = this.$phoneNumberFormatString(this.WarehouseForm.Phone);
      this.WarehouseForm.Address = this.location;
      const result = await this.$store.dispatch("updateWarehouse", {
        isWarehouse: this.isWarehouse,
        WarehouseForm: this.WarehouseForm,
        lat: this.marker.position.lat,
        lng: this.marker.position.lng,
      });
      if (!result.HasError) this.depotDialog = false;
      this.depotResponse = result;
    },
    updateBankInfo() {
      // if (this.userFormInputs.BankAccount.IBAN.length < 27)
      //   this.bankResponse = {
      //     ValidationErrors: [
      //       {
      //         Key: "IBAN",
      //         Value: "Lütfen hesap bilgilerinizi doğru giriniz"
      //       },
      //     ],
      //   };
      // else
      this.$store.dispatch("updateBankAccountInfo", {
        Title: this.userFormInputs.BankAccount?.Title,
        IBAN: this.userFormInputs.BankAccount?.IBAN.replace(/\s/g, ""),
      });
    },

    async updateUserInfo() {
      var req = {
        Content: this.userFormInputs.Content,
        IsWeb: true
      };
      this.$store.dispatch("sendReview", req);
    },
    async updateInvoiceInfo() {
      const result = await this.$store.dispatch("updateInvoiceInfo", {
        ...this.BillForm,
        PhoneNumber: this.$phoneNumberFormatString(this.BillForm.PhoneNumber),
      });
      if (!result.HasError) {
        this.invoiceDialog = false;
        this.invoiceResponse = { ValidationErrors: [] };
      }
    },
    async updatePassword() {
      var req = {
        CurrentPassword: this.currentPassword,
        NewPassword: this.newpassword,
        NewPasswordRetry: this.newpassword2,
      };
      await this.$store.dispatch("changePassword", req);
      this.passwordDialog = true;
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.marker.position = this.center;
        this.panToMarker();
      });
    },
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      // this.$refs.mapRef.setZoom(18);
    },
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },
    async handleMapClick(e) {
      try {
        const data = await geocoder.reverseGeocodeAsync(e.latLng.lat(), e.latLng.lng(), { key: this.apiKey });
        this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        this.center = {
          lat: this.marker.position.lat,
          lng: this.marker.position.lng,
        };
        let address = data.results.find((x) => x.types[0] == "street_address")?.formatted_address;
        if (!address) address = data.results.find((x) => x.types[0] == "route")?.formatted_address;
        if (!address) address = data.results[0].formatted_address;
        this.location = address;
      } catch (error) {
        console.log("err handleMapClick: ", error);
      }

      // this.location = address
    },
    next() {
      if (this.active++ > 1) this.active = 1;

      if (this.active === 2) {
        this.nextbtn = "Geri";
      } else {
        this.nextbtn = "Geri";
      }
    },

    handleAvatarSuccess(res) {
      if (res.HasError) {
        window.sendNotification.error(res.Message);
      } else {
        window.sendNotification.success(res.Message);
        this.BillForm.CompanyLogo = res.Data.Url;
      }
    },

    signOut() {
      this.$store.dispatch("signOutUser");
      this.signOutDialog = false;
      this.$router.push({ path: "/login" });
    },
  },
  beforeAvatarUpload(file) {
    const isJPG = file.type === "image/jpeg";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isJPG) {
      window.sendNotification.error("Avatar picture must be JPG format!");
    }
    if (!isLt2M) {
      window.sendNotification.error("Avatar picture size can not exceed 2MB!");
    }
    return isJPG && isLt2M;
  },
};
</script>

<style lang="scss">
@import "./style.scss";
</style>
